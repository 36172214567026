var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: {
        background: "#f0f2f5",
        "margin-top": "-20px",
        height: "100%",
      },
    },
    [
      _c("div", { staticClass: "wscn-http404" }, [
        _c("div", { staticClass: "pic-404" }, [
          _c("img", {
            staticClass: "pic-404__parent",
            attrs: { src: _vm.img_404, alt: "404" },
          }),
          _c("img", {
            staticClass: "pic-404__child left",
            attrs: { src: _vm.img_404_cloud, alt: "404" },
          }),
          _c("img", {
            staticClass: "pic-404__child mid",
            attrs: { src: _vm.img_404_cloud, alt: "404" },
          }),
          _c("img", {
            staticClass: "pic-404__child right",
            attrs: { src: _vm.img_404_cloud, alt: "404" },
          }),
        ]),
        _c("div", { staticClass: "bullshit" }, [
          _c("div", { staticClass: "bullshit__oops" }, [_vm._v("OOPS!")]),
          _c("div", { staticClass: "bullshit__headline" }, [
            _vm._v(_vm._s(_vm.message)),
          ]),
          _c("div", { staticClass: "bullshit__info" }, [
            _vm._v(
              " 请检查您输入的网址是否正确，请点击以下按钮返回主页或者发送错误报告 "
            ),
          ]),
          _c(
            "a",
            {
              staticClass: "bullshit__return-home",
              attrs: { href: "/admin/" },
            },
            [_vm._v("返回首页")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }